<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-02 10:25:36
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-04-19 11:15:39
 * @FilePath: \edp-web\src\views\memberList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="potential tab-page">
    <!-- <p class="tab-title">{{ $t("member.POtential") }}</p> -->
    <div class="block-item">
      <p class="block-title">{{ $t("member.testReport") }}</p>
      <div
        class="pot-block"
        v-infinite-scroll="load"
        :infinite-scroll-delay="500"
        :infinite-scroll-distance="10"
        :infinite-scroll-disabled="disabled"
      >
        <div class="pot-item fjsba" v-for="(item, i) in pageList" :key="i">
          <div class="pot-text">
            <div class="pot-tit">
              {{ i + 1 }}<br />
              {{ item.reportName }}
            </div>
            <div class="text-item">
              <span>{{ $t("member.Reportingtime") }}</span
              >{{ item.reportDate }}
            </div>
            <div class="text-item">
              <span>{{ $t("member.uploadtime") }}</span
              >{{ item.createTime }}
            </div>
            <div class="text-item">
              <span>{{ $t("member.Nameofuploader") }}</span
              >{{ item.createFullName }}
            </div>
          </div>
          <img
            @click="viewPort(item, item, i)"
            :src="item.reportThumbnailPrefix + item.reportThumbnailPath"
            alt=""
            class="report-img pot"
          />
        </div>
        <div class="list-empty" v-if="!pageList.length && !loading">
          <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
          <p>{{ $t("member.Nodata") }}</p>
          <div class="per-empty"></div>
        </div>
        <p
          v-if="noMore && pageTotal > 0 && !loading"
          class="end-wrap tac f16 pt10"
        >
          <span class="line"></span>
          <span class="txt">{{ $t("member.end") }}</span
          ><span class="line"></span>
        </p>
      </div>
      <p v-if="loading" class="tac">
        <van-loading size="24px" color="#3E886D" text-color="#3E886D">{{
          $t("member.loading")
        }}</van-loading>
      </p>
      <!-- <p v-if="noMore && pageList.length" class="tac f16 cg pt10">没有更多了</p> -->
    </div>
    <van-dialog
      v-if="dialogShowPage"
      :showConfirmButton="false"
      :showCancelButton="false"
      v-model="dialogShowPage"
      :close-on-click-overlay="true"
      class="edp-ability-dialog"
    >
      <section class="edp-ability-dialog__top">
        <div class="edp-ability-dialog__top__image">
          <img :src="dialogData.imageUrl" alt="" />
        </div>

        <div class="edp-ability-dialog__top__detail">
          <div>
            <div class="dadtd-content">
              <span v-if="$i18n.locale === 'cn'">{{
                dialogData.cnName || dialogData.enName
              }}</span>
              <span v-else>{{ dialogData.enName || dialogData.cnName }}</span>

              <em v-if="dialogData.empGenerations"
                ><span>{{ dialogData.empGenerations }}</span></em
              >
            </div>

            <p>{{ dialogData.reportName }}</p>
          </div>

          <span
            class="edp-ability-dialog__top__downloadBtn"
            @click="downloadReport"
          >
            {{ $t("ability.dialogDownloadBtn") }}
          </span>
        </div>
      </section>

      <section
        class="edp-ability-dialog__content"
        v-if="
          dialogData.reportImageList && dialogData.reportImageList.length > 0
        "
      >
        <div
          class="edp-ability-dialog__content__button eadcb-left"
          v-if="
            dialogData.reportImageList && dialogData.reportImageList.length > 1
          "
          :class="{ disable: dialogImgIndex == 0 }"
          @click="dialogImgClick('left')"
        >
          <div class="eadcb__icon"></div>
          <div class="eadcb__word">{{ $t("ability.dialogPrevWord") }}</div>
        </div>

        <div class="edp-ability-dialog__content__article">
          <swiper :ref="'mySwiper'" @slideChangeTransitionEnd="onSlideChange">
            <swiper-slide
              class="swiper-slide-mainImg"
              v-for="(item, index) in dialogData.reportImageList"
              :key="'mySwiper_abReportImage' + index"
            >
              <div class="swiper-slide-mainCotent">
                <img
                  v-for="(itemImage, indexImage) in item.imageUrl"
                  :key="'pdfImageItem' + indexImage"
                  :src="itemImage"
                  class="swiper-slide-mainImg"
                />

                <div class="waterSign">
                  <div
                    class="waterSign-in"
                    v-for="(itemWater, indexWater) in 200"
                    :key="'water' + indexWater"
                  >
                    {{ utils.sercetWaterSign(userInfo.cdsid) }}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div
          class="edp-ability-dialog__content__button eadcb-right"
          v-if="
            dialogData.reportImageList && dialogData.reportImageList.length > 1
          "
          :class="{
            disable: dialogImgIndex == dialogData.reportImageList.length - 1,
          }"
          @click="dialogImgClick('right')"
        >
          <div class="eadcb__icon"></div>
          <div class="eadcb__word">{{ $t("ability.dialogNextWord") }}</div>
        </div>
      </section>

      <span class="edp-ability-dialog_close" @click="closeDialog"></span>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
const pdfJS = require("pdfjs-dist");
pdfJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");
export default {
  name: "potential",
  props: ["id"],
  data() {
    return {
      count: 10,
      pageTotal: 0,
      query: {
        current: 1,
        size: 10,
      },
      pageList: [],
      dialogShowPage: false,
      dialogData: {
        cdsid: "",
        cnName: "",
        reportName: "",
        reportImage: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
      userInfo: (state) => state.login.userInfo,
    }),
    noMore() {
      return this.pageList.length >= this.pageTotal;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted() {
    //this.id = this.utils.decryption(this.$route.query.id);
    this.getList();
  },
  methods: {
    ...mapActions({
      evalReprort: "emp/evalReprort",
      abilityView: "ability/abilityView",
      abilityDown: "ability/abilityDown",
    }),
    load() {
      this.query.current++;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = { id: this.id, query: this.query };
      let res = await this.evalReprort(params);
      let arr = res.data.records;
      if (arr.length) {
        this.pageList = [...this.pageList, ...arr];
        this.query.current = res.data.current;
        this.pageTotal = res.data.total;
      } else {
        this.pageList = [];
      }
      this.loading = false;
    },
    convertPdfToImage(pdfUrl, indexOut) {
      let _this = this;

      const pdfData = pdfJS.getDocument(pdfUrl);

      pdfData.promise.then((pdf) => {
        for (let i = 1; i <= pdf._pdfInfo.numPages; i++) {
          _this.dialogData.reportImageList[indexOut].imageUrl.push("");
        }

        for (let i = 1; i <= pdf._pdfInfo.numPages; i++) {
          const canvas = document.createElement("canvas");

          pdf.getPage(i).then((page) => {
            // 获取页的尺寸
            const viewport = page.getViewport({ scale: 1 });

            // 设置canvas的尺寸
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const context = canvas.getContext("2d");

            // 将pdf页渲染到canvas上
            page
              .render({ canvasContext: context, viewport: viewport })
              .then(() => {
                _this.dialogData.reportImageList[indexOut].imageUrl[i - 1] =
                  canvas.toDataURL("image/png");

                _this.$forceUpdate();
              });
          });
        }
      });
    },
    viewPort(data, dataReport, indexReport) {
      this.dialogReportDetailList = data.evaluationDtos;

      this.$set(
        this.dialogData,
        "imageUrl",
        data.photo || this.defaultHeadImage
      );
      this.$set(this.dialogData, "cnName", data.fullName);
      // this.$set(this.dialogData, "enName", data.ename || "");
      this.$set(this.dialogData, "cdsid", data.cdsid);
      // this.$set(this.dialogData, "empGenerations", data.empGenerations || "");
      this.$set(this.dialogData, "reportName", dataReport.reportName);

      this.dialogData.reportImageList = this.pageList;

      this.dialogData.reportImageList.forEach(async (val, e) => {
        let res = await this.abilityView(val.id);

        this.$set(val, "imageUrl", []);

        this.convertPdfToImage(res.data.data, e);
      });

      this.dialogImgIndex = indexReport;
      this.reportIndexId = dataReport.id;

      this.dialogShowPage = true;

      setTimeout(() => {
        this.$refs.mySwiper.swiper.slideTo(this.dialogImgIndex, 500, false);
      }, 100);
    },
    dialogImgClick(type) {
      if (type === "left") {
        if (this.dialogImgIndex > 0) {
          this.dialogImgIndex = this.$refs["mySwiper"].swiper.activeIndex - 1;
          this.$refs.mySwiper.swiper.slideTo(this.dialogImgIndex, 500, false);
        }
      } else {
        if (this.dialogImgIndex < this.dialogData.reportImageList.length - 1) {
          this.dialogImgIndex = this.$refs["mySwiper"].swiper.activeIndex + 1;
          this.$refs.mySwiper.swiper.slideTo(this.dialogImgIndex, 500, false);
        }
      }

      this.$set(
        this.dialogData,
        "reportName",
        this.dialogData.reportImageList[this.dialogImgIndex].reportName
      );
      this.reportIndexId =
        this.dialogData.reportImageList[this.dialogImgIndex].id;
    },
    closeDialog() {
      this.dialogShowPage = false;
    },
    onSlideChange() {
      this.dialogImgIndex = this.$refs["mySwiper"].swiper.activeIndex;

      this.$set(
        this.dialogData,
        "reportName",
        this.dialogData.reportImageList[this.dialogImgIndex].reportName
      );
      this.reportIndexId =
        this.dialogData.reportImageList[this.dialogImgIndex].id;
    },
    async downloadReport() {
      let res = await this.abilityDown(this.reportIndexId);

      const name = this.dialogData.reportName;
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      a.download = name + ".pdf";
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
@import "@/src/styles/ability.scss";
.potential {
  .block-item {
    border-bottom: none;
    margin-right: 0;
    overflow: hidden;
    .pot-block {
      height: 68vh;
      overflow-y: auto;
      padding-right: toPad(6);
      .pot-item {
        padding-bottom: toPad(25);
        margin-top: toPad(25);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .pot-text {
          width: 65%;
          .pot-tit {
            margin-bottom: toPad(8);
            font-weight: 500;
            font-size: toPad(18);
            line-height: toPad(28);
          }
          .text-item {
            margin-bottom: toPad(4);
            font-size: toPad(16);
            line-height: toPad(22);
            span {
              display: inline-block;
              width: 25%;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
        .report-img {
          width: toPad(214);
          height: toPad(146);
          background: #ffffff;
          border: 1px solid #d1d1d1;
        }
      }
      .end-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: toPad(50) auto toPad(20);
        span {
          display: inline-block;
          &.line {
            width: toPad(66);
            height: toPad(1);
            background: rgba(0, 0, 0, 0.3);
          }
          &.txt {
            margin: 0 toPad(20);
            color: rgba(0, 0, 0, 0.21);
          }
        }
      }
      .list-empty {
        margin: toPad(100) 0 0;
        text-align: center;
        .empty-pic {
          width: toPad(90);
          height: toPad(90);
        }
        p {
          text-align: center;
          font-size: toPad(16);
        }
        .per-empty {
          width: 100%;
          height: toPad(387);
          margin-top: toPad(120);
          background: url("~@/src/assets/member/empty-two.png") no-repeat;
          background-size: cover;
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  .edp-ability-dialog__content {
    .edp-ability-dialog__content__article {
      .swiper-slide {
        height: toPad(400);
      }
    }
  }
}
</style>
