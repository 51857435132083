<template>
  <div class="chart-wrap">
    <div class="list-empty" v-show="!perList.length">
      <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
      <p>{{ $t("member.Comingsoon") }}</p>
      <div class="per-empty"></div>
    </div>
    <div class="per-chart" v-show="perList.length"></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      yearData: [],
      perList: [{}],
      upList: [],
    };
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getData();
  },
  methods: {
    ...mapActions({
      getBonusTrend: "moment/getBonusTrend",
    }),
    async getData() {
      let res = await this.getBonusTrend(this.id);
      if (res.data.length) {
        this.perList = [];
        this.perList = [...res.data];
        let upList = JSON.parse(JSON.stringify(this.perList));
        upList.forEach((e) => {
          this.yearData.push(e.currentYear);
          this.upList.push({
            name: e.currentYear,
            value: e.bonusCoefficient,
          });
        });
        this.drawChart();
      } else {
        this.perList = [];
      }
    },
    drawChart() {
      let myChart = echarts.init(this.$el.querySelector(".per-chart"));
      let option;

      option = {
        color: ["#61D6CC", "#FFD36C", "#FFD36C"],
        // legend: {
        //   show: true,
        //   bottom: 0,
        //   left: "center",
        //   itemWidth: 10,
        //   itemHeight: 10,
        // itemStyle: {
        //   borderCap: "round",
        //   borderRadius: 10,
        // },
        //},
        grid: {
          top: 40,
          bottom: 20,
          left: 0,
          right: 0,
          containLabel: true,
        },
        // tooltip: {
        //   trigger: "axis",
        // },
        xAxis: {
          type: "category",
          data: this.yearData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 14,
            // rotate: 40,
            //fontSize: 10,
          },
        },
        yAxis: [
          {
            // name: "      Yearly Growth",
            nameTextStyle: {
              width: 300,
            },
            type: "value",
            alignTicks: true,
            axisLabel: {
              formatter: "{value}M",
              color: "rgba(0, 0, 0, 0.3)",
              textStyle: {
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 0.2)",
                width: 1,
                type: "dashed",
              },
            },
          },
          // {
          //   name: "Cumulative Growth          ",
          //   type: "value",
          //   alignTicks: true,
          //   axisLabel: {
          //     formatter: "{value}%",
          //     color: "rgba(0, 0, 0, 0.3)",
          //     textStyle: {
          //       fontSize: 12,
          //     },
          //   },
          //   splitLine: {
          //     lineStyle: {
          //       color: "rgba(0, 0, 0, 0.2)",
          //       width: 1,
          //       type: "dashed",
          //     },
          //   },
          // },
        ],
        series: [
          {
            name: "Yearly Growth",
            data: this.upList,
            type: "bar",
            barMaxWidth: 35,
            //barMinWidth: 40,
            label: {
              show: true,
              position: "top",
              formatter: "{c}M",
            },
            z: 4,
            itemStyle: {
              borderWidth: 10,
            },
          },
        ],
      };

      option && myChart.setOption(option);
      $(window).resize(function () {
        console.log(111);
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
.chart-wrap {
  width: 100%;
  height: 100%;
  .per-chart {
    width: 100%;
    height: 100%;
  }
}
</style>
