<template>
  <div class="feedback tab-page">
    <p class="tab-title f16">
      {{ $t("member.360feedbackScore") }}
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('member.Datareporttime')"
        placement="right"
        popper-class="draw_share_atooltip"
      >
        <img src="@/src/assets/member/tips.png" alt="" class="tips"
      /></el-tooltip>
    </p>
    <div class="block-item">
      <p class="block-title"></p>
      <div class="total-block fjsba">
        <div class="total-left">
          <div class="rank-title fjsb">
            <div class="fcjsa">
              <div class="f14 fac fb">{{ $t("member.Mytotalscore") }}</div>
            </div>
            <span class="f26 fb cg3">{{
              scores.totalAvgScore === null ? "-" : scores.totalAvgScore
            }}</span>
          </div>
          <div class="rank-title fjsb">
            <div class="rank-title-item fcjsb">
              <div class="fjsa mb8">
                <div class="fcjsa top-text-left">
                  <span class="f12">
                    {{ $t("member.Departmentaveragescore") }}</span
                  >
                </div>
                <span class="f24 cg3 top-text-right">{{
                  scores.depAvg === null ? "-" : scores.depAvg
                }}</span>
              </div>
              <div class="fjsb">
                <span class="f12 bot-text-left">{{
                  $t("member.Departmentranking")
                }}</span>
                <span class="f16 fb tar bot-text-right">
                  {{ scores.depRank === null ? "-" : scores.depRank }}
                </span>
              </div>
            </div>
            <div class="rank-title-line"></div>
            <div class="rank-title-item fcjsb">
              <div class="fjsa mb8">
                <div class="fcjsa top-text-left">
                  <span class="f12">{{
                    $t("member.Companyaveragescore")
                  }}</span>
                </div>
                <span class="f24 cy top-text-right">{{
                  scores.enterpriseAvg === null ? "-" : scores.enterpriseAvg
                }}</span>
              </div>
              <div class="fjsb">
                <span class="f12 bot-text-left">{{
                  $t("member.Companyranking")
                }}</span>
                <span class="f16 fb tar bot-text-right">
                  {{
                    scores.enterpriseRank === null ? "-" : scores.enterpriseRank
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="total-right">
          <div class="rank-beats mt30">
            <div class="deats-wrap">
              <compareBar
                :ranking="parseInt(scores.enterpriseRank) || 0"
                :userTotal="scores.enterpriseUserTotal"
                :bgColor="'yellow'"
                :leftStyle="rank.leftStyle"
              ></compareBar>
              <p class="f14">
                <!-- {{ $t("member.Thecompanyranked") }}
                <span class="cg3"> {{ scores.enterpriseRank }}, </span> -->
                {{ $t("member.beating") }}
                <span class="cdd">
                  {{
                    scores.beatsEnterprisePercentage === null
                      ? "-"
                      : scores.beatsEnterprisePercentage
                  }}%
                </span>
                {{ $t("member.ofemployees") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title"></p>
      <div class="score-block fjsba">
        <div class="score-left">
          <p class="score-title f16 fb mb10">
            {{ $t("member.Receivedscore") }}
          </p>
          <div class="round-wrap fjsa">
            <div class="round-one round-item">
              <span class="round-num tac f34 bggreen-1">
                {{
                  receiveds.inviteAssessFromNum === null
                    ? "-"
                    : receiveds.inviteAssessFromNum
                }}
              </span>
              <span class="round-text mb16 f14">{{
                $t("member.wReceived")
              }}</span>
            </div>
            <div class="split-line"></div>
            <div class="round-two round-item">
              <span class="round-num tac f24 bgyellow-1">{{
                receiveds.inviteNum === null ? "-" : receiveds.inviteNum
              }}</span
              ><br />
              <span class="round-text f14"
                >{{ $t("member.Sendoutratinginvitation") }}<br />
                <span class="cg1"
                  >{{
                    receiveds.inviteReplyPercentage === null
                      ? "-"
                      : receiveds.inviteReplyPercentage
                  }}%</span
                ></span
              >
            </div>
          </div>
          <div class="number-wrap fcc">
            <p class="number-item pt6 mb8 f14">
              <span class="num-tit">{{
                $t("member.Activelyreceivedscores")
              }}</span>
              <span class="ml4 f26 cg tal">{{
                receiveds.activeAssessFromNum === null
                  ? "-"
                  : receiveds.activeAssessFromNum
              }}</span>
            </p>
            <p class="number-item mb8 f14">
              <span class="num-tit">{{ $t("member.Totalscorereceived") }}</span>
              <span class="ml4 f26 cg1">{{
                receiveds.assessFromNum === null ? "-" : receiveds.assessFromNum
              }}</span>
            </p>
          </div>
        </div>
        <div class="score-right">
          <p class="score-title f16 fb mb10">
            {{ $t("member.Commentstoothers") }}
          </p>
          <div class="round-wrap fjsa">
            <div class="round-one round-item">
              <span class="round-num tac f34 bgblue-1">
                {{
                  gives.inviteReplyFromNum === null
                    ? "-"
                    : gives.inviteReplyFromNum
                }}
              </span>

              <span class="round-text mb16 f14">
                {{ $t("member.Scoregiven") }}</span
              >
            </div>
            <div class="split-line"></div>
            <div class="round-two round-item">
              <span class="round-num tac f24 bgyellow-1">
                {{ gives.inviteFromNum === null ? "-" : gives.inviteFromNum }}
              </span>
              <span class="round-text f14"
                >{{ $t("member.Receivedratinginvitation") }}<br />
                <span class="cg1"
                  >{{
                    gives.assessReplyPercentage === null
                      ? "-"
                      : gives.assessReplyPercentage
                  }}%</span
                ></span
              >
            </div>
          </div>
          <div class="number-wrap fcc">
            <p class="number-item pt6 mb8 f14">
              <span class="num-tit">{{ $t("member.Activelyscoregiven") }}</span>
              <span class="ml4 f26 c55">{{
                gives.activeAssessNum === null ? "-" : gives.activeAssessNum
              }}</span>
            </p>
            <p class="number-item mb8 f14">
              <span class="num-tit">{{ $t("member.Totalscoregiven") }}</span>
              <span class="ml4 f26 c75">{{
                gives.assessNum === null ? "-" : gives.assessNum
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">
        {{ $t("member.Evaluationdisplay")
        }}<el-tooltip
          class="item"
          effect="dark"
          placement="right"
          popper-class="draw_share_atooltip"
        >
          <div v-html="$t('member.commentTips')" slot="content"></div>
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </p>
      <div class="chart-block">
        <div class="list-empty" v-show="!deptList.length">
          <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
          <p>{{ $t("member.Nodata") }}</p>
          <div class="per-empty"></div>
        </div>
        <!-- <div class="per-chart" id="per-chart" v-show="keywordList.length"></div> -->
        <div class="tab-blocks mb16" v-show="deptList.length">
          <div
            class="tab-item c0 fjc"
            :class="{ active: deptIndex == -1 }"
            @click="onTabs(-1)"
          >
            All ({{ allNum }})
          </div>
          <div
            class="tab-item c0 fjc"
            v-for="(item, i) in deptList"
            :key="i"
            :class="{ active: deptIndex == i }"
            @click="onTabs(i)"
          >
            {{ item.keywords }}
            ({{ item.keywordsNum }})
          </div>
        </div>
        <div class="keywords-block">
          <div
            class="keywords-item mb10"
            v-for="(item, i) in comments"
            :key="i"
          >
            <div class="tags fac mb8">
              <span class="dots b6c"></span>
              <span
                class="tag bggreen-1 f14 cf"
                v-for="(items, j) in item.keywords"
                :key="j"
                >{{ items }}
              </span>
            </div>
            <div class="texts f13">
              {{ item.evaluation }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import compareBar from "@/src/components/feedback/compareBar";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "feedback",
  props: ["id"],
  components: {
    compareBar,
  },
  data() {
    return {
      rank: {
        leftStyle: "1%",
      },
      scores: {
        depAvg: 0,
        depRank: 0,
        totalAvgScore: 0,
        enterpriseAvg: 0,
        enterpriseRank: 0,
        beatsEnterprisePercentage: 0,
        enterpriseUserTotal: 0,
      },
      receiveds: {},
      gives: {},
      keywordList: [
        { keywords: "communication", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },
        // { keywords: "工作", weight: 1.0 },

        // { keywords: "强", weight: 0.1 },
        // { keywords: "高", weight: 0.1 },
        // { keywords: "管理", weight: 0.1 },
        // { keywords: "同事", weight: 0.1 },
        // { keywords: "业务", weight: 0.1 },
        // { keywords: "完成", weight: 0.1 },
        // { keywords: "沟通", weight: 0.1 },
        // { keywords: "认真", weight: 0.1 },
        // { keywords: "了解", weight: 0.1 },

        // { keywords: "communication", weight: 0.9 },
        // { keywords: "communication", weight: 0.8 },
        // { keywords: "communication", weight: 0.7 },
        // { keywords: "communication", weight: 0.6 },
        // { keywords: "communication", weight: 0.5 },
        // { keywords: "communication", weight: 0.4 },
        // { keywords: "communication", weight: 0.3 },
        // { keywords: "communication", weight: 0.2 },
        // { keywords: "communication", weight: 0.1 },

        { keywords: "强", weight: 0.9 },
        { keywords: "高", weight: 0.8 },
        { keywords: "管理", weight: 0.7 },
        { keywords: "同事", weight: 0.6 },
        { keywords: "业务", weight: 0.5 },
        { keywords: "完成", weight: 0.4 },
        { keywords: "沟通", weight: 0.3 },
        { keywords: "认真", weight: 0.2 },
        { keywords: "了解", weight: 0.1 },
      ],
      deptList: [],
      deptIndex: -1,
      allNum: 0,
      comments: [],
    };
  },
  mounted() {
    //this.id = this.utils.decryption(this.$route.query.id);
    this.getScore();
    this.getReceived();
    this.getGive();
    // this.getKeywords();
    this.getKeywordsStat();
  },
  methods: {
    ...mapActions({
      empScore: "feedBack/empScore",
      received: "feedBack/received",
      give: "feedBack/give",
      keywords: "feedBack/keywords",
      keywordsStat: "feedBack/keywordsStat",
      commentList: "feedBack/commentList",
    }),
    async getScore() {
      let res = await this.empScore(this.id);
      this.scores = res.data;
      this.rank.leftStyle =
        100 - parseInt(this.scores.beatsEnterprisePercentage) + "%";
    },
    async getReceived() {
      let res = await this.received(this.id);
      this.receiveds = res.data;
    },
    async getGive() {
      let res = await this.give(this.id);
      this.gives = res.data;
    },
    async getKeywords() {
      let res = await this.keywords(this.id);
      this.keywordList = res.data;
      this.drawChart();
    },
    drawChart() {
      var chartDom = document.getElementById("per-chart");
      var myChart = echarts.init(chartDom);

      //字符串截取
      let wordLength = (value) => {
        let ret = "";
        let maxLength = 6;
        let valLength = value.length;
        if (valLength > maxLength) {
          ret = value.substring(0, maxLength) + "...";
          return ret;
        } else {
          ret = value;
          return ret;
        }
      };
      // var wordLength = (value) => {
      //   var ret = ""; //拼接加\n返回的类目项
      //   var maxLength = 18; //每项显示文字个数
      //   var valLength = value.length; //X轴类目项的文字个数
      //   var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
      //   if (rowN > 1) {
      //     //如果类目项的文字大于3,
      //     for (var i = 0; i < rowN; i++) {
      //       var temp = ""; //每次截取的字符串
      //       var start = i * maxLength; //开始截取的位置
      //       var end = start + maxLength; //结束截取的位置
      //       //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
      //       temp = value.substring(start, end) + "\n";
      //       ret += temp; //凭借最终的字符串
      //     }
      //     return ret;
      //   } else {
      //     return value;
      //   }
      // };
      //偏移量
      var offsetData = [
        [56, 56],
        [35, 77],
        [32, 40],
        [78, 43],
        [15, 28],
        [72, 82],
        [15, 66],
        [45, 20],
        [88, 68],
        [66, 20],
      ];
      // 颜色
      let colors = [
        { c1: "#8CBBE2", c2: "#BEEFFF" },
        { c1: "#FAC446", c2: "#F2E4B2" },
        { c1: "#E7C963", c2: "#FDF6D5" },
        { c1: "#76CC9E", c2: "#BEE4BE" },
        { c1: "#47BEAB", c2: "#ACE0D2" },
        { c1: "#71E8DB", c2: "#CEFFF8" },
        { c1: "#B5A386", c2: "#DFCEB2" },
        { c1: "#76CC9E", c2: "#BEE4BE" },
        { c1: "#9EB2C4", c2: "#D1DEEA" },
        { c1: "#89BDE9", c2: "#CBDBFA" },
      ];
      //循环定义series的data值
      var datas = [];
      this.keywordList.forEach((e, i) => {
        let size = 176,
          font = 23,
          maxLength = 15;
        if (1 > e.weight > 0.9) {
          size = 156;
          font = 19;
          maxLength = 12;
        }
        if (0.9 >= e.weight > 0.8) {
          size = 136;
          font = 18;
          maxLength = 10;
        }
        if (0.8 >= e.weight > 0.6) {
          size = 116;
          font = 18;
          maxLength = 8;
        }
        if (0.6 >= e.weight > 0.4) {
          size = 96;
          font = 18;
          maxLength = 7;
        }
        if (0.4 >= e.weight > 0.2) {
          size = 86;
          font = 16;
          maxLength = 5;
        }
        if (0.2 >= e.weight > 0) {
          size = 76;
          font = 12;
          maxLength = 5;
        }
        datas.push({
          name: e.keywords,
          value: offsetData[i],
          symbolSize: size,
          label: {
            textStyle: {
              fontSize: font,
            },
            formatter: (val) => {
              let value = val.name;
              let ret = "";
              // let maxLength = 15;
              let valLength = value.length;
              if (valLength > maxLength) {
                ret = value.substring(0, maxLength) + "...";
                return ret;
              } else {
                ret = value;
                return ret;
              }
            },
          },
          itemStyle: {
            color: new echarts.graphic.RadialGradient(0.78, 0.62, 0.7, [
              {
                offset: 0,
                color: colors[i].c1,
              },
              {
                offset: 1,
                color: colors[i].c2,
              },
            ]),
            opacity: 1,
            shadowColor: "rgba(0,0,0,0.2)",
            shadowBlur: 10,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
          },
        });
      });
      var option = {
        //   backgroundColor: "transparent",
        // backgroundColor: "#0e2147",
        grid: {
          show: false,
          top: 5,
          bottom: 5,
          left: "6%",
          right: "6%",
        },
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{b}",
        },
        xAxis: [
          {
            gridIndex: 0,
            type: "value",
            show: false,
            min: 0,
            max: 100,
            nameLocation: "middle",
            nameGap: 5,
          },
        ],
        yAxis: [
          {
            gridIndex: 0,
            min: 0,
            show: false,
            max: 100,
            nameLocation: "middle",
            nameGap: 30,
          },
        ],

        series: [
          {
            type: "scatter",
            symbol: "circle",
            symbolSize: 120,
            label: {
              normal: {
                show: true,
                formatter: "{b}",
                color: "#fff",
                textStyle: {
                  fontSize: "20",
                },
              },
            },

            itemStyle: {
              normal: {
                color: "#00acea",
              },
            },
            data: datas,
          },
        ],
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    onTabs(val) {
      this.deptIndex = val;
      this.getCommentList();
    },
    async getKeywordsStat() {
      let res = await this.keywordsStat(this.id);
      this.deptList = res.data;
      if (this.deptList.length) {
        this.deptList.forEach((e) => {
          this.allNum += e.keywordsNum;
        });
        this.getCommentList();
      }
    },
    async getCommentList() {
      let keyword;
      if (this.deptIndex === -1) {
        keyword = "";
      } else {
        keyword = this.deptList[this.deptIndex].keywords;
      }
      let params = {
        id: this.id,
        query: {
          current: 1,
          size: 2000,
          keywords: keyword,
        },
      };
      let res = await this.commentList(params);
      this.comments = res.data.records;
      this.comments.forEach((e) => {
        if (e.keywords.length) {
          e.keywords = e.keywords.split(",");
        }
      });
      if (this.deptIndex === -1) {
        this.allNum = res.data.total;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
@media screen and (max-width: 1600px) {
  .feedback {
    .tab-title {
      display: flex;
      align-items: center;
      font-size: toPad(16);
      .tips {
        width: toPad(16);
        height: toPad(16);
        margin-left: toPad(6);
        cursor: pointer;
      }
    }
    .block-item {
      .block-title {
        display: flex;
        align-items: center;
        .tips {
          width: toPad(16);
          height: toPad(16);
          margin-left: toPad(6);
          cursor: pointer;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      .total-block {
        .total-left {
          width: 50%;
          .rank-title {
            padding: toPad(19) toPad(4);
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            &:last-of-type {
              border-bottom: none;
            }
            .rank-title-item {
              width: 45%;
              height: toPad(72);
              // span {
              //   width: 50%;
              // }
              .top-text-left {
                width: 60%;
              }
              .top-text-right {
                width: 40%;
                text-align: right;
              }
              .bot-text-left {
                width: 60%;
              }
              .bot-text-right {
                width: 40%;
              }
            }
            .rank-title-line {
              position: relative;
              bottom: toPad(0);
              width: 1px;
              height: toPad(60);
              background: rgba(0, 0, 0, 0.1);
            }
          }
        }
        .total-right {
          width: 50%;
          .deats-wrap {
            float: right;
            width: 80%;
          }
        }
      }
      .score-block {
        .score-left,
        .score-right {
          display: flex;
          justify-content: space-between;
          position: relative;
          width: 49%;
          padding: toPad(40) 0;
          .score-title {
            position: absolute;
            left: 0;
            top: 0;
          }
          .round-wrap {
            align-items: flex-start;
            width: toPad(170);
            padding-right: toPad(8);
            border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
            .round-one {
              width: toPad(62);
              margin-right: toPad(16);
              .round-text {
                width: toPad(62);
                display: inline-block;
                text-align: center;
              }
              .round-num {
                display: inline-block;
                width: toPad(62);
                height: toPad(62);
                line-height: toPad(62);
                margin-bottom: toPad(6);
                border-radius: 50%;
                color: #ffffff;
              }
            }
            .round-two {
              position: relative;
              top: toPad(56);
              vertical-align: top;
              text-align: center;
              .round-text {
                display: block;
                text-align: center;
              }
              .round-num {
                display: inline-block;
                width: toPad(44);
                height: toPad(44);
                line-height: toPad(44);
                margin-bottom: toPad(6);
                border-radius: 50%;
                color: #ffffff;
              }
            }
            .split-line {
              position: relative;
              top: toPad(30);
              left: toPad(4);
              width: 1px;
              height: toPad(60);
              mix-blend-mode: multiply;
              background: rgba(0, 0, 0, 0.2);
              transform: rotate(30deg);
            }
          }
          .number-wrap {
            width: 49%;
            .number-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .num-tit {
                width: 60%;
              }
            }
          }
        }
      }
      .chart-block {
        .per-chart {
          width: 100%;
          height: toPad(520);
        }
        .tab-blocks {
          display: flex;
          flex-wrap: wrap;
          //padding: toPad(20) toPad(30) toPad(10);
          //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          .tab-item {
            //height: toPad(48);
            padding: toPad(8) toPad(6);
            border-radius: toPad(2);
            margin-right: toPad(4);
            margin-bottom: toPad(4);
            font-size: toPad(13);
            background: #f7f7f7;
            &.active {
              background: rgba(109, 182, 147, 0.29);
            }
          }
        }
        .keywords-block {
          max-height: toPad(360);
          overflow-y: auto;
          .keywords-item {
            .tags {
              .dots {
                display: inline-block;
                width: toPad(7);
                height: toPad(7);
                border-radius: 50%;
                margin-right: toPad(8);
              }
              .tag {
                display: inline-block;
                padding: toPad(2) toPad(5);
                border-radius: toPad(5) 0 toPad(5) 0;
                margin-right: toPad(4);
              }
            }
            .texts {
              padding-left: toPad(15);
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1601px) {
  .feedback {
    .tab-title {
      display: flex;
      align-items: center;
      font-size: toPad(16);
      .tips {
        width: toPad(18);
        height: toPad(18);
        margin-left: toPad(6);
        cursor: pointer;
      }
    }
    .block-item {
      .block-title {
        display: flex;
        align-items: center;
        .tips {
          width: toPad(16);
          height: toPad(16);
          margin-left: toPad(6);
          cursor: pointer;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      .total-block {
        .total-left {
          width: 50%;
          .rank-title {
            padding: toPad(19) toPad(4);
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            &:last-of-type {
              border-bottom: none;
            }
            .rank-title-item {
              width: 45%;
              height: toPad(72);
              // span {
              //   width: 50%;
              // }
              .top-text-left {
                width: 60%;
              }
              .top-text-right {
                width: 40%;
                text-align: right;
              }
              .bot-text-left {
                width: 60%;
              }
              .bot-text-right {
                width: 40%;
              }
            }
            .rank-title-line {
              position: relative;
              bottom: toPad(0);
              width: 1px;
              height: toPad(60);
              background: rgba(0, 0, 0, 0.1);
            }
          }
        }
        .total-right {
          width: 50%;
          .deats-wrap {
            float: right;
            width: 80%;
          }
        }
      }
      .score-block {
        .score-left,
        .score-right {
          display: flex;
          justify-content: space-between;
          position: relative;
          width: 49%;
          padding: toPad(40) 0;
          .score-title {
            position: absolute;
            left: 0;
            top: 0;
          }
          .round-wrap {
            align-items: flex-start;
            width: toPad(170);
            padding-right: toPad(8);
            border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
            .round-one {
              width: toPad(62);
              margin-right: toPad(16);
              .round-text {
                width: toPad(62);
                display: inline-block;
                text-align: center;
              }
              .round-num {
                display: inline-block;
                width: toPad(62);
                height: toPad(62);
                line-height: toPad(62);
                margin-bottom: toPad(6);
                border-radius: 50%;
                color: #ffffff;
              }
            }
            .round-two {
              position: relative;
              top: toPad(56);
              vertical-align: top;
              text-align: center;
              .round-text {
                display: block;
                text-align: center;
              }
              .round-num {
                display: inline-block;
                width: toPad(44);
                height: toPad(44);
                line-height: toPad(44);
                margin-bottom: toPad(6);
                border-radius: 50%;
                color: #ffffff;
              }
            }
            .split-line {
              position: relative;
              top: toPad(30);
              left: toPad(4);
              width: 1px;
              height: toPad(60);
              mix-blend-mode: multiply;
              background: rgba(0, 0, 0, 0.2);
              transform: rotate(30deg);
            }
          }
          .number-wrap {
            width: 49%;
            .number-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .num-tit {
                width: 60%;
              }
            }
          }
        }
      }
      .chart-block {
        .per-chart {
          width: 90%;
          height: toPad(480);
          margin: 0 auto;
        }
        .tab-blocks {
          display: flex;
          flex-wrap: wrap;
          //padding: toPad(20) toPad(30) toPad(10);
          //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          .tab-item {
            //height: toPad(48);
            padding: toPad(8) toPad(6);
            border-radius: toPad(2);
            margin-right: toPad(4);
            margin-bottom: toPad(4);
            font-size: toPad(13);
            background: #f7f7f7;
            &.active {
              background: rgba(109, 182, 147, 0.29);
            }
          }
        }
        .keywords-block {
          max-height: toPad(360);
          overflow-y: auto;
          .keywords-item {
            .tags {
              .dots {
                display: inline-block;
                width: toPad(7);
                height: toPad(7);
                border-radius: 50%;
                margin-right: toPad(8);
              }
              .tag {
                display: inline-block;
                padding: toPad(2) toPad(5);
                border-radius: toPad(5) 0 toPad(5) 0;
                margin-right: toPad(4);
              }
            }
            .texts {
              padding-left: toPad(15);
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.draw_share_atooltip {
  background: #dff2f0 !important;
  color: #000 !important;
}
</style>
