<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-02 10:25:36
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-06-29 16:12:57
 * @FilePath: \edp-web\src\views\memberList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="experience tab-page">
    <!-- <p class="tab-title">{{ $t("member.WorkeE") }}</p> -->
    <div class="block-item">
      <p class="block-title">{{ $t("member.JLRtransferpromotion") }}</p>
      <div v-if="jlrJobList.length" class="transfer-block">
        <div class="tra-list" v-for="(item, i) in jlrJobList" :key="i">
          <span class="point"></span>
          <span class="date">{{ item.changeTime }}</span>
          <span class="type">{{ item.changeType }}</span>
          <span class="title" :class="{ over: isUat }">{{
            item.jobPosition
          }}</span>
          <span class="time"
            ><span v-if="i == 0 && lang === 'cn'"
              >{{ $t("member.untilnow") }}
            </span>
            {{ item.stayTimeStr }}
            <span v-if="i == 0 && lang === 'en'">{{
              $t("member.untilnow")
            }}</span></span
          >
        </div>
      </div>

      <div class="list-empty" v-if="!jlrJobList.length">
        <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
        <p>{{ $t("member.Nodata") }}</p>
        <div class="per-empty"></div>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">{{ $t("member.Industrydistribution") }}</p>

      <div class="list-empty" v-show="!tradeList.length">
        <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
        <p>{{ $t("member.Nodata") }}</p>
        <div class="per-empty"></div>
      </div>
      <div class="field-block" v-show="tradeList.length">
        <div class="field-chart" id="main"></div>
        <div class="field-data">
          <div
            class="data-item-block fjsb txt-elps"
            v-for="(item, i) in tradeList"
            :key="i"
          >
            <p class="data-item txt-elps">
              <em>
                <span
                  v-bind:style="{ background: item.itemStyle.color }"
                ></span>
                {{ item.value }}%
              </em>
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">{{ $t("member.Allworkexperience") }}</p>
      <div class="all-block" v-if="jobList.length">
        <div class="all-list">
          <span class="date">{{ $t("member.Formeremployer") }}</span>
          <span class="type">{{ $t("member.Industryclassification") }}</span>
          <span class="title">{{ $t("member.Workingyears") }}</span>
          <span class="time">{{ $t("member.Jobposition") }}</span>
        </div>
        <div class="all-list" v-for="(item, i) in jobList" :key="i">
          <span class="date">{{ item.lastCompany }}</span>
          <span class="type">{{
            utils.formatLang(lang, item.tradeCn, item.trade)
          }}</span>
          <!-- <span class="title">{{ item.workYears }}</span> -->
          <span class="title" v-if="i == 0">
            {{ item.workStartTime }} -
            {{ item.workEndTime || $t("member.untilnow") }}
          </span>
          <span class="title" v-else>
            {{ item.workStartTime }} -
            {{ item.workEndTime || "-" }}
          </span>
          <span class="time">{{
            utils.formatLang(lang, item.jobPositionCn, item.jobPosition)
          }}</span>
        </div>
      </div>
      <div class="list-empty" v-if="!jobList.length">
        <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
        <p>{{ $t("member.Nodata") }}</p>
        <div class="per-empty"></div>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">{{ $t("member.Projectexperience") }}</p>
      <div class="chart-content">
        <projectChart></projectChart>
      </div>
      <div class="project-block" v-if="proList.length">
        <div class="pro-list" v-for="(item, i) in proList" :key="i">
          <div class="pro-left">
            <span class="order">{{ $t("member.project") }} {{ item.id }}</span>
            <p class="pro-title mb8">
              <span class="op5">{{ $t("member.Formeremployer") }}：</span><br />
              <span>{{ item.projectEmployer }}</span>
            </p>
            <p class="pro-title mb8">
              <span class="op5">{{ $t("member.Projectname") }}：</span><br />
              <span>{{ item.projectName }}</span>
            </p>
            <p class="pro-title">
              <span class="op5">{{ $t("member.Projectcycle") }}：</span><br />
              <span>{{ item.projectBeginTime }}-{{ item.projectEndTime }}</span>
            </p>
          </div>

          <div class="pro-right">
            <p class="pro-title op5">{{ $t("member.Projectcontent") }}：</p>
            <p class="pro-content" v-html="item.projectContent"></p>
          </div>
        </div>
      </div>
      <div class="list-empty" v-if="!proList.length">
        <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
        <p>{{ $t("member.Nodata") }}</p>
        <div class="per-empty"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import projectChart from "../talent/projectChart.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "experience",
  //props: ["id"],
  components: {
    projectChart,
  },
  data() {
    return {
      jobList: [],
      jlrJobList: [],
      proList: [],
      colors: [
        "rgba(113, 164, 161, 0.8)",
        "#61BFB4",
        "#EDCA75",
        "#CAAD7F",
        "#6796C1",
      ],
      tradeList: [],
      isUat: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getJobJlr();
    this.getJobInfo();
    this.getProjectInfo();
    console.log(process.env.NODE_ENV);
    if (process.env.VUE_APP_MODES === "uat") {
      this.isUat = true;
    } else {
      this.isUat = false;
    }
  },
  methods: {
    ...mapActions({
      jobInfo: "emp/jobInfo",
      jobJlr: "emp/jobJlr",
      projectInfo: "emp/projectInfo",
    }),
    async getJobJlr() {
      let res = await this.jobJlr(this.id);
      console.log(res);
      this.jlrJobList = res.data;
    },
    async getJobInfo() {
      let res = await this.jobInfo(this.id);
      console.log(res);
      this.jobList = res.data.data;
      let trades = res.data.trade;
      let num = 0;
      trades.forEach((e) => {
        let item = {
          value: e.score,
          name: this.lang == "en" ? e.trade : e.tradeCn,
          itemStyle: {
            color: this.colors[num],
          },
        };
        this.tradeList.push(item);
        num++;
      });

      this.drawChart();
    },
    async getProjectInfo() {
      let res = await this.projectInfo(this.id);
      console.log(res);
      this.proList = res.data;
    },
    drawChart() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          top: 20,
          left: 10,
          right: 10,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          show: false,
          type: "value",
        },
        tooltip: {
          position: [0, 0],
          formatter: "{b} {c}%",
        },
        label: {
          width: 80,
          overflow: "truncate",
          ellipsis: "...",
        },
        series: [
          {
            data: [...this.tradeList],
            type: "bar",
            label: {
              show: true,
              position: "top",
              formatter: "{b}",
              fontSize: 14,
            },
            barMaxWidth: 80,
            barMinWidth: 40,
          },
        ],
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.tab-page {
  padding: toPad(25) toPad(20) 0 toPad(20);

  .tab-title {
    margin-left: toPad(20);
    font-weight: 600;
    font-size: toPad(20);
    line-height: toPad(25);
  }

  .block-item {
    margin: toPad(25) toPad(20) 0;
    padding-bottom: toPad(10);
    border-bottom: 0.950464px solid rgba(0, 0, 0, 0.1);

    .block-title {
      margin-bottom: toPad(22);
      font-weight: 600;
      font-size: toPad(16);
      line-height: toPad(18);
    }
    &:first-of-type {
      margin-top: 0;
    }
  }

  &.experience {
    width: 100%;
    .transfer-block {
      width: 100%;
      .tra-list {
        overflow: hidden;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: toPad(24);
        margin-bottom: toPad(10);
        font-size: toPad(14);
        span {
          margin-right: toPad(10);
          display: inline-block;
          text-align: left;
          &:last-of-type {
            margin-right: 0;
          }
          &.point {
            width: toPad(10);
            height: toPad(10);
            border-radius: 50%;
            background: #d9d9d9;
          }

          &.date {
            width: 16%;
            color: rgba(0, 0, 0, 0.5);
          }

          &.type {
            width: 15%;
          }

          &.title {
            width: 38%;
            &.over {
              overflow: hidden;
            }
          }

          &.time {
            width: 30%;
          }
        }

        &:nth-of-type(1) {
          font-size: toPad(16);
          font-weight: 600;

          .point {
            background: #61d6cc;
          }
        }
      }
    }

    .all-block {
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-bottom: toPad(10);

      .all-list {
        overflow: hidden;
        width: 100%;
        min-height: toPad(44);
        padding: toPad(10) toPad(12);
        font-size: toPad(14);
        border-bottom: 0.69px solid rgba(0, 0, 0, 0.1);
        span {
          display: inline-block;
          vertical-align: middle;
          &.date {
            width: 30%;
          }

          &.type {
            width: 23%;
          }

          &.title {
            width: 23%;
          }

          &.time {
            width: 24%;
          }
        }

        &:nth-of-type(1) {
          color: #fff;
          background: #8ccbc6;
          border-bottom: 1px solid #fff;
        }

        &:nth-of-type(2) {
          background: #edf7f3;
          border-bottom: 1px solid #fff;
        }

        &:last-of-type {
          border-bottom: 1px solid #fff;
        }
      }
    }
    .chart-content {
      width: 100%;
      height: toPad(280);
      overflow: hidden;
    }
    .project-block {
      margin-bottom: toPad(10);

      .pro-list {
        display: flex;
        border: 1px solid #c7d7da;
        margin-bottom: toPad(10);

        .pro-left {
          position: relative;
          width: toPad(156);
          padding: toPad(40) toPad(5) toPad(20) toPad(15);
          background: #eff5f7;
          font-size: toPad(14);

          .order {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            min-width: toPad(67);
            height: toPad(23);
            line-height: toPad(23);
            padding: 0 toPad(6);
            text-align: center;
            color: #fff;
            background: #caad7f;
          }

          .pro-title {
            span {
              font-weight: 600;
            }
          }
        }

        .pro-right {
          width: toPad(580);
          padding: toPad(20) toPad(1) toPad(20) toPad(15);
          font-size: toPad(13);

          .pro-content {
            font-size: toPad(13);
            line-height: toPad(23);
            white-space: pre-wrap;
          }

          ul {
            li {
              height: toPad(18);
              line-height: toPad(18);

              span {
                position: relative;
                top: toPad(-4);
                display: inline-block;
                width: toPad(4);
                height: toPad(4);
                margin-right: toPad(4);
                border-radius: 2px;
                background: #000;
              }
            }
          }
        }
      }
    }

    .field-block {
      position: relative;
      display: flex;
      align-items: flex-end;
      .field-chart {
        width: toPad(360);
        height: toPad(160);

        canvas {
          width: 100%;
          height: 100%;
        }
      }

      .field-data {
        //position: absolute;
        //right: 0;
        //bottom: toPad(10);
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: toPad(345);
        min-height: toPad(160);
        .data-item-block {
          width: toPad(345);
          .data-item {
            max-width: toPad(340);
            height: toPad(37);
            line-height: toPad(37);
            padding: 0 toPad(10);
            margin-bottom: toPad(10);
            background: #f6f6f6;
            border-radius: toPad(44);
            font-size: toPad(14);
            em {
              font-style: normal;
              margin-right: toPad(6);
              font-size: toPad(18);
              font-weight: 600;
              span {
                display: inline-block;
                width: toPad(12);
                height: toPad(12);
                margin-right: toPad(6);
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}
.list-empty {
  margin: toPad(50) 0;
  text-align: center;
  .empty-pic {
    width: toPad(90);
    height: toPad(90);
  }
  p {
    text-align: center;
    font-size: toPad(16);
  }
}
</style>
