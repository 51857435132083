<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-02 10:25:36
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-07-13 16:28:03
 * @FilePath: \edp-web\src\views\memberList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="performance tab-page">
    <!-- <p class="tab-title">{{ $t("member.PerformanceR") }}</p> -->
    <div class="block-item">
      <p class="block-title bots">{{ $t("member.PerformanceR") }}</p>
      <div class="per-block">
        <div class="per-text fjsb" v-if="perList.length">
          <div class="text-left mb20">
            <span
              class="label"
              v-html="ToBreak($t('member.Latestscores'))"
            ></span>
            <span class="num c64">{{ lastData.performanceScore }}</span>
            <p class="diff f12" v-if="perList.length > 1">
              <img
                v-if="lastData.performanceFluctuation > 0"
                src="@/src/assets/member/up-icon.png"
                alt=""
                class="up-icon"
              />
              <img
                v-if="lastData.performanceFluctuation < 0"
                src="@/src/assets/member/down-icon.png"
                alt=""
                class="up-icon"
              /><span class="c0 f18 fb ml4">{{
                lastData.performanceFluctuation === null
                  ? ""
                  : Math.abs(lastData.performanceFluctuation)
              }}</span>
              <br />
              <span class="op7">{{ $t("member.Comparedprevious") }}</span>
            </p>
          </div>
          <div class="text-right">
            <img
              src="@/src/assets/member/per-icon.png"
              alt=""
              class="right-pic"
            />
            <img :src="perFace" alt="" class="face-pic" />
          </div>
        </div>
        <template v-else>
          <div class="per-empty">
            <p class="f16 op5" v-html="ToBreak($t('member.perEmpty'))"></p>
          </div>
        </template>
        <div class="per-chart" id="per-chart"></div>
      </div>
      <div class="block-line" :class="{ empty: perList.length == 0 }"></div>
    </div>
    
    <div class="block-item">
      <p class="block-title">{{ $t("moment.SalaryYoYG") }}</p>
      <div class="chart-content">
        <salaryChart></salaryChart>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">{{ $t("moment.BonusTrend") }}</p>
      <div class="chart-content">
        <bonusTrendChart></bonusTrendChart>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">{{ $t("moment.TotalPackage") }}</p>
      <div class="chart-content">
        <totalChart></totalChart>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">
        {{ $t("moment.Benchmark") }}
        <el-tooltip
          class="item"
          effect="dark"
          placement="right"
          popper-class="draw_share_atooltip"
        >
          <div v-html="$t('moment.BanchMarkTips')" slot="content"></div>
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </p>
      <div class="chart-content">
        <benchmarkChart></benchmarkChart>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">
        {{ $t("moment.Benchmark-t")
        }}<el-tooltip
          class="item"
          effect="dark"
          placement="right"
          popper-class="draw_share_atooltip"
        >
          <div v-html="$t('moment.BanchMarkTips')" slot="content"></div>
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </p>
      <div class="chart-content">
        <benchmarkTotal></benchmarkTotal>
      </div>
    </div>
  </div>
</template>

<script>
import salaryChart from "../talent/salaryChart.vue";
import bonusTrendChart from "../talent/bonusTrendChart.vue";
import totalChart from "../talent/totalChart.vue";
import benchmarkChart from "../talent/benchmarkChart.vue";
import benchmarkTotal from "../talent/benchmarkTotalChart.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  name: "performance",
  props: ["id"],
  components: {
    salaryChart,
    bonusTrendChart,
    totalChart,
    benchmarkChart,
    benchmarkTotal,
  },
  data() {
    return {
      yearData: [],
      perList: [{}],
      fiscalList: [],
      currentList: [],
      lastData: {},
      perFace: "",
    };
  },
  mounted() {
    //this.id = this.utils.decryption(this.$route.query.id);
    this.getPer();
  },
  methods: {
    ...mapActions({
      perform: "emp/perform",
    }),
    ToBreak(val) {
      return val.replaceAll(".", "<br />");
    },
    async getPer() {
      let res = await this.perform(this.id);
      if (res.data.fiscalYear.length || res.data.naturalYear.length) {
        this.perList = [];
        this.lastData = {
          performanceScore: res.data.performanceScore,
          performanceFluctuation: res.data.performanceFluctuation,
        };
        this.perList = [...res.data.fiscalYear, ...res.data.naturalYear];
        if (this.lastData.performanceScore) {
          this.perFace = require(`@/src/assets/member/score-${this.lastData.performanceScore}.png`);
        } else {
          this.perFace = require(`@/src/assets/member/score-1.png`);
        }

        res.data.fiscalYear.forEach((e) => {
          this.fiscalList.push({
            name: e.currentYear,
            value: e.performanceScore,
            fiscalYear: e.fiscalYear,
          });
        });
        res.data.naturalYear.forEach((e) => {
          let text = e.currentYear + this.$t("member.Years");
          this.yearData.push(text);
          this.currentList.push({
            name: e.currentYear,
            value: e.performanceScore,
            fiscalYear: e.fiscalYear,
          });
        });
        this.drawChart();
      } else {
        this.perFace = require(`@/src/assets/member/score-1.png`);
        this.perList = [];
      }
    },
    drawChart() {
      var chartDom = document.getElementById("per-chart");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#61BFB4", "#FFD36C"],
        grid: {
          top: 30,
          left: 20,
          bottom: 30,
          containLabel: true,
        },
        // legend: {
        //   zlevel: 5,
        //   bottom: 0,
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   itemStyle: {
        //     borderCap: "round",
        //     borderRadius: 10,
        //   },
        // },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.yearData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 18,
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 5,
          splitLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.2)",
              width: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: this.$t("member.naturalyear"),
            data: this.currentList,
            type: "line",
            connectNulls: true,
            Symbol: "circle",
            symbolSize: 18,
            lineStyle: {
              width: 3,
            },
            label: {
              show: false,
              position: "top",
            },
            z: 4,
            itemStyle: {
              borderWidth: 10,
            },
          },
          {
            name: this.$t("member.Fiscalyear"),
            data: this.fiscalList,
            type: "line",
            connectNulls: true,
            symbolSize: 18,
            symbolOffset: [0, 3],
            lineStyle: {
              width: 3,
            },
            label: {
              show: false,
              position: "top",
            },
          },
        ],
      };

      option && myChart.setOption(option);
      $(window).resize(function () {
        console.log(111);
        myChart.resize();
      });
      // window.addEventListener("resize", () => {
      //   console.log("resize");
      //   myChart.setOption(option, true);
      //   myChart.resize();
      // });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.performance {
  .block-item {
    position: relative;
    margin-top: 0;
    margin-bottom: toPad(20);
    border-bottom: 1px solid #fff;
    .block-title {
      .tips {
        display: inline-block;
        width: toPad(15);
        height: toPad(15);
        margin-left: toPad(8);
        cursor: pointer;
      }
    }
    .bots {
      margin-bottom: 0;
    }
    
    .block-line {
      width: 85%;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      &.empty {
        margin-top: toPad(147);
      }
    }
  }
  .per-block {
    position: relative;
    margin-bottom: toPad(20);
    .per-text {
      .text-left {
        display: flex;
        align-items: flex-end;
        font-size: toPad(16);
        .label {
          display: inline-block;
          //width: toPad(80);
        }
        .num {
          display: inline-block;
          position: relative;
          top: toPad(14);
          margin: 0 toPad(24) 0 toPad(20);
          font-weight: 600;
          font-size: toPad(72);
        }
        .diff {
          font-size: toPad(12);
          .up-icon {
            width: toPad(15);
            height: toPad(15);
            position: relative;
            top: toPad(2);
          }
          .op7 {
            opacity: 0.7;
          }
        }
      }
      .text-right {
        position: relative;
        top: toPad(30);
        width: toPad(100);
        height: toPad(100);
        .right-pic {
          width: toPad(100);
          height: toPad(100);
        }
        .face-pic {
          position: absolute;
          top: toPad(-50);
          right: toPad(20);
          width: toPad(35);
          height: toPad(35);
          border: toPad(6) solid #efefef;
          border-radius: 50%;
          background: #efefef;
        }
      }
    }
    .per-chart {
      width: 100%;
      height: toPad(240);
    }
    .per-empty {
      position: absolute;
      left: 0;
      top: 0;
      //z-index: 3;
      overflow: hidden;
      width: toPad(750);
      height: toPad(387);
      background: url("~@/src/assets/member/per-empty.png") no-repeat;
      background-size: cover;
      p {
        margin: toPad(150) 0 0 toPad(100);
      }
    }
  }
  .chart-content {
    width: 100%;
    height: toPad(280);
    overflow: hidden;
  }
}
</style>
