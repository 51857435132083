<template>
  <section>
    <div class="member-detail">
      <edp-header
        class="header-bg"
        :headerLeftWord="$t('member.detailHeader')"
      ></edp-header>
      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("member.dataRange2") }}</span>
      </div>

      <div class="edp-common-content member-detail-content">
        <no-permit v-if="noPermitStatus" :noWord="noPermitStWord" />

        <section class="fjsba" style="width: 100%" v-else>
          <div class="desc-wrap">
            <div class="base-block info-block">
              <p class="block-title">{{ $t("member.Employeesinformation") }}</p>
              <div class="member-item">
                <img
                  :src="detail.photo || nullImg"
                  alt=""
                  class="item-pic"
                  @click="picVisible = true"
                />
                <div class="item-text fcjsb">
                  <div class="text-one">
                    <span class="name-text">{{
                      utils.formatLang(lang, detail.cname, detail.ename)
                    }}</span>
                    <em v-if="detail.empGenerations" class="lable"
                      ><span>{{ detail.empGenerations }}</span></em
                    >
                  </div>
                  <div class="text-two">
                    <span class="tag">{{ detail.gender }}</span
                    ><span class="tag"
                      >{{ detail.age }} {{ $t("member.yearsold") }}</span
                    ><span class="tag" v-if="detail.baseAddress">{{
                      detail.baseAddress
                    }}</span
                    ><span class="tag">{{ detail.educationBackground }}</span
                    ><span class="tag" v-if="detail.childless == 1">{{
                      $t("member.Havechild")
                    }}</span>
                    <span class="tag" v-else-if="detail.childless == 0">{{
                      $t("member.Childless")
                    }}</span>
                    <span class="tag" v-else>{{ $t("member.unknown") }}</span>
                  </div>
                  <div class="text-thr">
                    <span class="name">{{ $t("member.Employeenumber") }}</span>
                    <span class="desc">{{ detail.jobNum }}</span>
                  </div>
                  <div class="text-thr">
                    <span class="name">{{ $t("member.Mobilephone") }}</span>
                    <span class="desc">{{ detail.phone }}</span>
                  </div>
                  <div class="text-thr">
                    <span class="name">{{ $t("member.Email") }}</span>
                    <span class="desc">{{ detail.mail }}</span>
                  </div>
                </div>
              </div>
              <div class="num-wrap">
                <div class="b64 num-block">
                  <div class="num-item p0 fc b64">
                    <p>
                      <span class="nums">{{
                        detail.totalServiceYear === null
                          ? "-"
                          : detail.totalServiceYear
                      }}</span
                      >{{ $t("member.Year") }}
                    </p>
                    <span class="num-desc">{{ $t("member.Totalyears") }}</span>
                  </div>
                  <div class="num-item fc b64">
                    <p>
                      <span class="nums">{{
                        detail.jrlWorkYear === null ? "-" : detail.jrlWorkYear
                      }}</span
                      >{{ $t("member.Year") }}
                    </p>
                    <span class="num-desc">{{
                      $t("member.WorkingyearsJLR")
                    }}</span>
                  </div>
                </div>
                <div class="num-block">
                  <div class="num-item fc b6c">
                    <p>
                      <span class="nums">{{
                        detail.lastPerformance === null
                          ? "-"
                          : detail.lastPerformance
                      }}</span>
                    </p>
                    <span class="num-desc">{{
                      $t("member.Performancescore")
                    }}</span>
                  </div>
                </div>
                <div class="num-block">
                  <div class="num-item fc bfb">
                    <p>
                      <span class="nums">{{
                        detail.fieldOfficeRate === null
                          ? "-"
                          : detail.fieldOfficeRate
                      }}</span
                      >%
                    </p>
                    <span class="num-desc">{{ $t("member.Attendance") }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="base-block tag-block"
              v-if="detail.empMedalInfos && detail.empMedalInfos.length"
            >
              <p class="block-title">{{ $t("member.Personaltag") }}</p>
              <span
                class="tag"
                v-for="(item, i) in detail.empMedalInfos"
                :key="i"
              >
                <img
                  :src="item.medalIconHost + item.medalIconUrl"
                  alt=""
                  class="tag-icon"
                />
                {{
                  utils.formatLang(lang, item.medalCnName, item.medalEnName)
                }}</span
              >
            </div>
            <div class="base-block dep-block">
              <p class="block-title">{{ $t("member.Departmentposition") }}</p>
              <div class="dep-item" v-if="detail.org">
                <div>
                  <span class="dep-label">{{ $t("member.Organization") }}</span
                  ><br />
                  <p class="dep-content">{{ detail.org }}</p>
                </div>
              </div>
              <div class="dep-item" v-if="detail.dept">
                <div>
                  <span class="dep-label">{{ $t("member.Departments") }}</span>
                  <br />
                  <p class="dep-content">
                    {{ utils.formatLang(lang, detail.dept, detail.deptEname) }}
                  </p>
                </div>
              </div>
              <div class="dep-item" v-if="detail.subdivision">
                <div>
                  <span class="dep-label">{{
                    $t("member.Sub-departments")
                  }}</span>
                  <br />
                  <p class="dep-content">{{ detail.subdivision }}</p>
                </div>
              </div>
              <div class="dep-item" v-if="detail.directLeaderChineseName">
                <div>
                  <span class="dep-label">{{
                    $t("member.Immediateleader")
                  }}</span>
                  <br />
                  <p class="dep-content">
                    {{
                      utils.formatLang(
                        lang,
                        detail.directLeaderChineseName,
                        detail.directLeaderEnName
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="dep-item" v-if="detail.jobPosition">
                <div>
                  <span class="dep-label">{{ $t("member.Jobposition") }}</span>
                  <br />
                  <p class="dep-content">
                    {{
                      utils.formatLang(
                        lang,
                        detail.jobPositionCn,
                        detail.jobPosition
                      )
                    }}
                  </p>
                </div>
              </div>
              <div v-if="detail.directSubordinate > 0" class="dep-item">
                <div>
                  <span class="dep-label">{{
                    $t("member.Directsubordinate")
                  }}</span>
                  <br />
                  <p class="dep-content">
                    {{ detail.directSubordinate }}{{ $t("member.People") }}
                    <span class="dep-link f14" @click="dialogVisible = true">{{
                      $t("member.View")
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="dep-item" v-if="detail.hiredate">
                <div>
                  <span class="dep-label">{{ $t("member.Hiredate") }}</span>
                  <br />
                  <p class="dep-content">{{ detail.hiredate }}</p>
                </div>
              </div>
            </div>
            <div class="base-block record-block">
              <p class="block-title">{{ $t("member.Resume") }}</p>
              <div class="dep-item" v-if="detail.educationalExp.length">
                <div class="dep-label">{{ $t("member.Schoollevel") }}</div>
                <div
                  class="edu-block"
                  v-for="(item, i) in detail.educationalExp"
                  :key="i"
                >
                  <p class="fjsb mb6">
                    <span>{{
                      utils.formatLang(
                        lang,
                        item.schoolCnName,
                        item.schoolEnName
                      )
                    }}</span>
                    <span
                      >{{ item.startSchoolDate }}-{{
                        item.finishSchoolDate
                      }}</span
                    >
                  </p>
                  <p class="mb6 op5">
                    <span>{{
                      utils.formatLang(
                        lang,
                        item.educationCnName,
                        item.educationEnName
                      )
                    }}</span>
                    &nbsp;
                    <span>{{
                      utils.formatLang(lang, item.majorCnName, item.majorEnName)
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="dep-item" v-if="detail.workExperience">
                <div class="dep-label">
                  {{ $t("member.Personaladvantages") }}
                </div>
                <p class="word-spac">
                  {{ detail.workExperience }}
                </p>
              </div>
              <div class="dep-item" v-if="detail.majorProject">
                <div class="dep-label">{{ $t("member.Majorproject") }}</div>
                <p>
                  {{ detail.majorProject }}
                </p>
              </div>
              <div class="dep-item" v-if="detail.professionalSkill">
                <div class="dep-label">
                  {{ $t("member.Professionalskill") }}
                </div>
                <p class="word-spac">{{ detail.professionalSkill }}</p>
              </div>
              <div class="dep-item" v-if="detail.lastCompany">
                <div class="dep-label">{{ $t("member.previousempoyler") }}</div>
                <p>{{ detail.lastCompany }}</p>
              </div>
              <div class="dep-item" v-if="detail.hobbyInterest">
                <div class="dep-label">{{ $t("member.Interest") }}</div>
                <p class="word-spac">
                  {{
                    utils.formatLang(
                      lang,
                      detail.hobbyInterestCn,
                      detail.hobbyInterest
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="tab-wrap">
            <div class="tab-block pot">
              <div
                class="tab-item fjc"
                :class="{ active: tabIndex == 0 }"
                @click="onTab(0)"
              >
                {{ $t("member.Workexperience") }}
              </div>
              <div
                v-if="isLeader"
                class="tab-item fjc"
                :class="{ active: tabIndex == 1 }"
                @click="onTab(1)"
              >
                {{ $t("moment.TalentGrowth") }}
              </div>
              <div
                class="tab-item fjc"
                :class="{ active: tabIndex == 2 }"
                @click="onTab(2)"
              >
                {{ $t("member.Potential") }}
              </div>
              <div
                class="tab-item fjc"
                :class="{ active: tabIndex == 3 }"
                @click="onTab(3)"
              >
                {{ $t("member.Workingstatus") }}
              </div>
              <div
                class="tab-item fjc"
                :class="{ active: tabIndex == 4 }"
                @click="onTab(4)"
              >
                360Feedback
              </div>
            </div>
            <div class="tab-view">
              <experience v-if="tabIndex == 0"></experience>
              <performance :id="id" v-if="tabIndex == 1"></performance>
              <potential :id="id" v-if="tabIndex == 2"></potential>
              <states :id="id" v-if="tabIndex == 3"></states>
              <feedback :id="id" v-if="tabIndex == 4"></feedback>
            </div>

            <div class="notice-wrap" v-if="noticeShow">
              <div class="notice-content" v-if="noticeInshow">
                <img
                  src="../assets/common/close.png"
                  alt=""
                  class="close"
                  @click="noticeInshow = false"
                />
                <p class="f20 tac mt20 mb24">{{ $t("moment.notice") }}</p>
                <p
                  class="f16 tac mb32 lh15"
                  v-html="$t('moment.noticeText')"
                ></p>
                <div class="but-con tac">
                  <el-button class="but" @click="noticeInshow = false">{{
                    $t("edpBackendCommon.canale")
                  }}</el-button>
                  <el-button
                    type="primary"
                    class="confirm but"
                    @click="noticeShow = false"
                    >{{ $t("moment.Confirm") }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- 直线下属 -->
      <el-dialog
        :title="$t('member.Directsubordinate')"
        :visible.sync="dialogVisible"
        width="50%"
        top="22vh"
        custom-class="sub-dialog"
      >
        <div class="sub-wrap">
          <div
            class="member-item"
            v-for="(item, i) in subs"
            :key="i"
            @click="goDetail(item)"
          >
            <img
              :src="item.subordinatePhoto || nullImg"
              alt=""
              class="item-pic"
              :class="{ 'dml-noPower-backop': !item.isEmpower }"
            />
            <div
              class="item-text fcjsb"
              :class="{ 'dml-noPower-backop': !item.isEmpower }"
            >
              <div class="text-one">
                <span class="name-text txt-elps">{{
                  utils.formatLang(
                    lang,
                    item.subordinateCname,
                    item.subordinateEname
                  )
                }}</span>
                <!-- <span v-else class="name-text txt-elps">{{
                item.subordinateEname || item.subordinateCname
              }}</span> -->
                <em v-if="item.empGenerations" class="lable"
                  ><span>{{ item.empGenerations }}</span></em
                >
              </div>
              <div class="text-thr">
                {{
                  utils.formatLang(
                    lang,
                    item.subordinatePositionCn,
                    item.subordinatePosition
                  )
                }}
              </div>
            </div>
            <div class="dml-noPower" v-if="!item.isEmpower">
              {{ $t("departmentDetail.noLimitMemberList") }}
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 头像弹窗 -->
      <el-dialog
        :visible.sync="picVisible"
        width="50%"
        custom-class="sub-dialog"
      >
        <img :src="detail.photo || nullImg" alt="" class="header-pic" />
      </el-dialog>
    </div>
  </section>
</template>

<script>
import experience from "../components/member/workExperience.vue";
import performance from "../components/member/performance.vue";
import potential from "../components/member/potential.vue";
import states from "../components/member/workStates.vue";
import feedback from "../components/member/feedback.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    experience,
    performance,
    potential,
    states,
    feedback,
  },
  data() {
    return {
      tabIndex: 0,
      dialogVisible: false,
      picVisible: false,
      detail: {
        educationalExp: [],
      },
      id: "",
      subs: [],
      nullImg: require("@/src/assets/common/default.png"),
      isLeader: false,
      noticeShow: false,
      noticeInshow: false,

      noPermitStatus: true,
      noPermitStWord: true,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
      userInfo: (state) => state.login.userInfo,
    }),
  },

  async created() {
    this.noPermitStWord = true;
    this.id = this.utils.decryption(this.$route.query.id);
    let resCheck = await this.userRptPermitCheck(this.id);

    if (resCheck.data && resCheck.data.data) {
      this.noPermitStatus = false;
    } else {
      this.noPermitStatus = true;
      this.noPermitStWord = false;
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.isLeader = this.userInfo.permissions.some((e) => e == "leader");
    // }, 300);
    let sstUs = setInterval(() => {
      if (this.userInfo && this.userInfo.permissions) {
        this.isLeader = this.userInfo.permissions.some(
          (e) => e == "per:report:prf-salary:ro"
        );
        clearInterval(sstUs);
      }
    }, 100);

    this.getDetail();
  },
  watch: {
    "$route.query.id"(val, oldVal) {
      window.location.reload();
    },
  },
  methods: {
    ...mapActions({
      empDetail: "emp/empDetail",
      subList: "emp/subList",
      userRptPermitCheck: "emp/userRptPermitCheck",
    }),
    onTab(val) {
      this.tabIndex = val;
      if (val == 1) {
        this.noticeShow = true;
        this.noticeInshow = true;
      } else {
        this.noticeShow = false;
      }
    },
    async getDetail() {
      let res = await this.empDetail(this.id);

      this.detail = res.data;
      // if (this.detail.empTag && this.detail.empTag.length) {
      //   this.detail.empTag = this.detail.empTag.split(",");
      // }
      if (this.detail.hobbyInterestCn && this.detail.hobbyInterestCn.length) {
        this.detail.hobbyInterestCn = res.data.hobbyInterestCn.replace(
          /,/g,
          ",  "
        );
      }
      if (this.detail.hobbyInterest && this.detail.hobbyInterest.length) {
        this.detail.hobbyInterest = this.detail.hobbyInterest.replace(
          /,/g,
          ",  "
        );
      }
      if (this.detail.workExperience && this.detail.workExperience.length) {
        this.detail.workExperience = res.data.workExperience.replace(
          /,/g,
          ",  "
        );
      }
      if (
        this.detail.professionalSkill &&
        this.detail.professionalSkill.length
      ) {
        this.detail.professionalSkill = this.detail.professionalSkill.replace(
          /,/g,
          ",  "
        );
      }

      if (this.detail.directSubordinate > 0) {
        this.getList();
      }
    },
    async getList() {
      let res = await this.subList(this.id);
      this.subs = res.data;
    },
    goDetail(val) {
      if (!val.isEmpower) {
        return false;
      }
      this.tabIndex = 10;
      this.dialogVisible = false;
      // this.id = val.directSubordinate;
      // this.getDetail();
      let sercetCd = this.utils.encryption(val.directSubordinate);

      let thisPath = this.$route.path;

      this.$router.push({
        path: "/memberDetailInside",
        query: { id: sercetCd },
      });
      setTimeout(() => {
        this.tabIndex = 0;
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.member-detail {
  .member-detail-content {
    padding: toPad(25) toPad(40) toPad(20);
    .desc-wrap {
      width: 34%;
      max-height: 85vh;
      overflow-y: auto;
      .base-block {
        overflow-x: hidden;
        width: 100%;
        padding: toPad(20);
        margin-bottom: toPad(20);
        background: #ffffff;
        box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
        border-radius: 10px;

        .block-title {
          font-weight: 600;
          margin-bottom: toPad(16);
          font-size: toPad(16);
        }

        &.info-block {
          .block-title {
            font-size: toPad(20);
            line-height: toPad(28);
          }

          .num-wrap {
            display: flex;
            justify-content: space-around;
            width: 100%;
            color: #fff;
            font-size: toPad(10);
            .num-block {
              display: flex;
              width: 24%;
              margin-right: toPad(10);
              border-radius: toPad(5);
              &:first-of-type {
                width: 46%;
                justify-content: space-around;
                //padding-right: 0;
              }
              .num-item {
                align-items: center;
                width: 100%;
                //height: toPad(80);
                border-radius: toPad(5);
                padding: toPad(8);
                .nums {
                  margin-bottom: toPad(6);
                  font-size: toPad(30);
                  //line-height: toPad(46);
                }

                .num-desc {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  width: toPad(80);
                  min-height: toPad(28);
                  padding: toPad(5) 0;
                  //line-height: toPad(28);
                  background: rgba(255, 255, 255, 0.16);
                  border-radius: 3px;
                }
                &.p0 {
                  padding-right: 0;
                }
              }
            }
          }
        }

        &.tag-block {
          .tag {
            display: inline-flex;
            padding: toPad(1) toPad(5) toPad(3);
            height: toPad(22);
            font-size: toPad(14);
            margin-right: 4px;
            background: rgba(0, 0, 0, 0.05);
            .tag-icon {
              width: toPad(20);
              height: toPad(20);
            }
          }
        }

        &.dep-block {
          .dep-item {
            width: 100%;
            display: flex;
            font-size: toPad(16);
            margin-bottom: toPad(10);
            div {
              width: 100%;
              // margin-right: toPad(30);
              .dep-label {
                padding: toPad(2) toPad(5) toPad(3);
                height: toPad(23);
                font-size: toPad(14);
                margin-right: 4px;
                color: #fff;
                background: #caad7f;
              }
              .dep-content {
                display: block;
                margin-top: toPad(12);
              }
              .dep-link {
                display: inline-block;
                padding: toPad(2) toPad(5);
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: toPad(16);
              }
            }
          }
        }
        &.record-block {
          .dep-item {
            font-size: toPad(16);
            margin-bottom: toPad(10);
            .dep-label {
              display: inline-block;
              padding: toPad(3) toPad(5) toPad(2);
              margin-bottom: toPad(10);
              height: toPad(24);
              line-height: 1.4;
              font-size: toPad(14);
              margin-right: 4px;
              color: #fff;
              background: #a4aab0;
            }
            .word-spac {
              word-spacing: toPad(4);
            }
            .edu-block {
              padding: toPad(6) 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              &:last-of-type {
                border-bottom: 1px solid #fff;
              }
              p {
                max-width: 100%;
                //overflow: hidden;
              }
            }
          }
        }
      }
    }

    .tab-wrap {
      position: relative;
      width: 64.5%;
      max-height: 85vh;
      background: #fff;
      box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
      border-radius: 10px;
      .tab-block {
        display: flex;
        justify-content: space-around;
        padding: toPad(20) toPad(30) toPad(10);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .tab-item {
          //height: toPad(48);
          padding: toPad(8) toPad(16);
          border-radius: toPad(6);
          margin-right: toPad(12);
          text-align: center;
          line-height: 1;
          &.active {
            background: rgba(109, 182, 147, 0.29);
            font-weight: 600;
          }
        }
      }
      .tab-view {
        max-height: 76.5vh;
        overflow-y: auto;
      }
      .notice-wrap {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: toPad(61);
        width: 100%;
        height: 91%;
        background: rgba(255, 255, 255, 0.71);
        backdrop-filter: blur(6.5px);
        .notice-content {
          overflow: hidden;
          position: relative;
          width: toPad(371);
          height: toPad(209);
          border-radius: 10px;
          margin: toPad(70) auto;
          background: url("~@/src/assets/member/notice-bg.png") no-repeat;
          background-size: 100%;
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.12);
          .close {
            position: absolute;
            right: toPad(15);
            top: toPad(15);
            width: toPad(18);
            height: toPad(18);
          }
          .but-con {
            .but {
              width: toPad(94);
              padding: 12px 0;
              &.confirm {
                background: #3e886d;
              }
            }
          }
        }
      }
    }
  }
}
.member-item {
  position: relative;
  display: flex;
  width: 96%;
  margin: toPad(10) 0 toPad(16);

  .item-pic {
    width: toPad(66);
    height: toPad(66);
    border-radius: toPad(40);
    margin-right: toPad(20);
  }

  .item-text {
    width: 84%;
    .text-one {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: toPad(4);
      font-weight: 500;
      font-size: toPad(18);
      .name-text {
        display: inline-block;
        max-width: 80%;
      }
      .lable {
        display: inline-flex;
        color: #fff;
        border-radius: toPad(5) 0px;
        background: linear-gradient(90.61deg, #60cfc6 3.65%, #61bfb4 99.54%);
        padding: 0 toPad(4);
        text-align: center;
        margin-left: toPad(6);
        font-style: normal;
        border: 1px solid rgba(152, 244, 235, 0.6);
        line-height: toPad(16);

        span {
          transform: scale(0.83);
          font-size: toPad(12);
        }
      }
    }

    .text-two {
      margin-bottom: toPad(4);
      .tag {
        display: inline-block;
        padding: 0px toPad(5);
        height: toPad(18);
        line-height: toPad(18);
        margin-right: toPad(4);
        margin-bottom: toPad(4);
        background: rgba(0, 0, 0, 0.05);
        white-space: nowrap;
      }
    }

    .text-thr {
      display: flex;
      align-items: flex-start;
      margin-bottom: toPad(4);
      font-size: toPad(14);
      color: rgba(0, 0, 0, 0.5);

      span {
        display: inline-block;
        &.name {
          width: 17%;
        }
        &.desc {
          width: 83%;
          color: #000;
          word-break: break-all;
        }
      }
    }
  }
}
.sub-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 50vh;
  padding: 0 toPad(20);
  overflow-y: auto;
  .member-item {
    width: 20vw;
    height: toPad(66);
    //border: 1px solid #f9f9f9;
    .item-text {
      width: 70%;
      justify-content: center;
      .text-one {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: toPad(8);
        font-weight: 500;
        font-size: toPad(18);
        .name-text {
          display: inline-block;
          max-width: 70%;
        }
      }
    }
  }
}
.sub-dialog {
  border-radius: toPad(8);
  background: url("~@/src/assets/common/BG.png") no-repeat center;
  background-size: cover;
}
.header-pic {
  display: block;
  width: toPad(420);
  height: toPad(420);
  margin: 0 auto;
}
</style>
