<template>
  <div class="chart-wrap">
    <div class="list-empty" v-show="!perList.length">
      <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
      <p>{{ $t("member.Nodata") }}</p>
      <div class="per-empty"></div>
    </div>
    <div class="per-chart" v-show="perList.length"></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      yearData: [],
      perList: [{}],
      peerList: [],
      abbList: [],
      nevList: [],
      entryList: [],
    };
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getData();
  },
  methods: {
    ...mapActions({
      getBenchmark: "moment/getBenchmark",
    }),
    async getData() {
      let res = await this.getBenchmark(this.id);
      if (res.data.length) {
        this.perList = [];
        this.perList = [...res.data];
        let upList = JSON.parse(JSON.stringify(this.perList));
        upList.forEach((e) => {
          let text = e.currentYear;
          this.yearData.push(text);
          this.peerList.push({
            name: e.currentYear,
            value: e.gloriaSalaryVsPeer,
          });
          this.abbList.push({
            name: e.currentYear,
            value: e.gloriaSalaryVsAbb,
          });
          this.nevList.push({
            name: e.currentYear,
            value: e.gloriaSalaryVsNev,
          });
          this.entryList.push({
            name: e.currentYear,
            value: e.totalPackage,
          });
        });
        this.drawChart();
      } else {
        this.perList = [];
      }
    },
    drawChart() {
      let myChart = echarts.init(this.$el.querySelector(".per-chart"));
      let option;

      option = {
        color: ["#F4CA6B", "#61BFB4", "#A8C5BA", "#77A4E5"],
        legend: {
          show: true,
          bottom: 0,
          left: "center",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 30,
          // itemStyle: {
          //   borderCap: "round",
          //   borderRadius: 10,
          // },
        },
        grid: {
          top: 40,
          bottom: 25,
          left: 25,
          right: 0,
          containLabel: true,
        },
        // tooltip: {
        //   trigger: "axis",
        // },
        xAxis: {
          type: "category",
          data: this.yearData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 14,
            // rotate: 40,
            //fontSize: 10,
          },
        },
        yAxis: [
          {
            // name: "      Yearly Growth",
            // nameTextStyle: {
            //   width: 300,
            // },
            show: false,
            type: "value",
            // alignTicks: true,
            splitNumber: 1,
            // min: 40,
            // minInterval: 50,
            //scale: true,
            axisLabel: {
              formatter: "{value}%",
              color: "rgba(0, 0, 0, 0.3)",
              textStyle: {
                fontSize: 12,
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(0, 0, 0, 0.2)",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: this.$t("moment.peer"),
            data: this.peerList,
            type: "bar",
            barMaxWidth: 35,
            //barMinWidth: 40,
            label: {
              show: true,
              position: "top",
              formatter: "{c}%",
            },
            //z: 4,
            itemStyle: {
              borderWidth: 10,
            },
            markLine: {
              symbol: "none",
              label: {
                show: true,
                position: "start",
                formatter: "Employee",
              },
              lineStyle: {
                width: 2,
                color: "rgba(0,0,0,0.2)",
              },
              data: [
                {
                  yAxis: 100,
                },
              ],
            },
          },
          {
            name: this.$t("moment.Abb"),
            data: this.abbList,
            type: "bar",
            barMaxWidth: 35,
            //barMinWidth: 40,
            label: {
              show: true,
              position: "top",
              formatter: "{c}%",
            },
            itemStyle: {
              borderWidth: 10,
            },
          },
          {
            name: this.$t("moment.Nev"),
            data: this.nevList,
            type: "bar",
            barMaxWidth: 35,
            //barMinWidth: 40,
            label: {
              show: true,
              position: "top",
              formatter: "{c}%",
            },
            itemStyle: {
              borderWidth: 10,
            },
          },
          // {
          //   name: "",
          //   // data: this.entryList,
          //   type: "line",
          //   // zlevel: 2,
          //   // yAxisIndex: 1,
          //   markLine: {
          //     symbol: "none",
          //     label: {
          //       show: true,
          //       position: "start",
          //       formatter: "Employee",
          //     },
          //     lineStyle: {
          //       width: 2,
          //       color: "rgba(0,0,0,0.2)",
          //     },
          //     data: [
          //       {
          //         yAxis: 100,
          //       },
          //     ],
          //   },
          //   symbol: "line",
          //   lineStyle: {
          //     width: 2,
          //     type: "dashed",
          //     color: "#77A4E5",
          //   },
          //   label: {
          //     show: false,
          //     position: "top",
          //     formatter: "{c}%",
          //   },
          // },
        ],
      };

      option && myChart.setOption(option);
      $(window).resize(function () {
        console.log(111);
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
.chart-wrap {
  width: 100%;
  height: 100%;
  .per-chart {
    width: 100%;
    height: 100%;
  }
}
</style>
