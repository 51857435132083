<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-14 15:58:14
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-07-13 16:26:24
 * @FilePath: \edp-web\src\components\member\workStates.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="work-states tab-page">
    <!-- <p class="tab-title">{{ $t("member.WOrkingstatus") }}</p> -->
    <div class="block-item">
      <p class="block-title">{{ $t("member.Mywork") }}</p>
      <div class="states-block">
        <div class="states-list fjsba">
          <div class="work-item">
            <div class="left-tit">
              {{ $t("member.On-siteworkingrate") }}
              <el-tooltip
                class="item"
                effect="dark"
                placement="right"
                popper-class="draw_share_atooltip"
              >
                <div
                  v-html="ToBreak($t('member.On-siteDesc'))"
                  slot="content"
                ></div>
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>
            <div class="fjsba">
              <div class="text-left">
                <div class="left-num c53">
                  {{
                    detail.fieldOfficeRate === null
                      ? "-"
                      : detail.fieldOfficeRate
                  }}%
                </div>
              </div>
              <div class="text-right">
                <div class="right-num">
                  <template v-if="detail.fieldOfficeRatePercentage !== null">
                    <span v-if="detail.fieldOfficeRatePercentage >= 0"
                      ><img
                        src="@/src/assets/member/up-icon.png"
                        alt=""
                      />+</span
                    ><img
                      v-if="detail.fieldOfficeRatePercentage < 0"
                      src="@/src/assets/member/down-icon.png"
                      alt=""
                  /></template>
                  {{
                    detail.fieldOfficeRatePercentage === null
                      ? "-"
                      : detail.fieldOfficeRatePercentage
                  }}%
                </div>
                <div class="right-tit opt5">
                  {{ $t("member.comparedCompany") }}
                </div>
              </div>
            </div>
          </div>
          <div class="work-item">
            <div class="left-tit">
              {{ $t("member.Averageworkighours") }}
              <el-tooltip
                class="item"
                effect="dark"
                placement="right"
                popper-class="draw_share_atooltip"
              >
                <div
                  v-html="ToBreak($t('member.Avedesc'))"
                  slot="content"
                ></div>
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>
            <div class="fjsba">
              <div class="text-left">
                <div class="left-num c6d">
                  {{
                    detail.averageWorkingHour === null
                      ? "-"
                      : detail.averageWorkingHour
                  }}
                  <span>{{ $t("member.hours") }}</span>
                </div>
              </div>
              <div class="text-right">
                <div class="right-num">
                  <template v-if="detail.fieldOfficeRatePercentage !== null">
                    <span v-if="detail.averageWorkingHourPercentage >= 0"
                      ><img
                        src="@/src/assets/member/up-icon.png"
                        alt=""
                      />+</span
                    ><img
                      v-if="detail.averageWorkingHourPercentage < 0"
                      src="@/src/assets/member/down-icon.png"
                      alt=""
                  /></template>
                  {{
                    detail.averageWorkingHourPercentage === null
                      ? "-"
                      : detail.averageWorkingHourPercentage
                  }}%
                </div>
                <div class="right-tit opt5">
                  {{ $t("member.comparedCompany") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="states-list fjsba">
          <div class="work-item">
            <div class="left-tit">
              {{ $t("member.Recordoftraining") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('member.TrainingDesc')"
                placement="right"
                popper-class="draw_share_atooltip"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>
            <div class="fjsba">
              <div class="text-left">
                <div class="left-num c7e">
                  {{ detail.trainingNums === null ? "-" : detail.trainingNums
                  }}<span>{{ $t("member.sections") }}</span>
                </div>
              </div>
              <div class="text-right">
                <div class="right-tit">
                  {{ $t("member.Numbercoursesattended") }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="work-item fjsba">
            <div class="text-left">
              <div class="left-tit">
                {{ $t("member.Cumlativeworkinghours") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('member.CumlativHoursDesc')"
                  placement="right"
                  popper-class="draw_share_atooltip"
                >
                  <img src="@/src/assets/member/tips.png" alt="" class="tips"
                /></el-tooltip>
              </div>
              <div class="left-num c7e">
                {{ detail.addUpWorkingHour || 0
                }}<span>{{ $t("member.hours") }}</span>
              </div>
            </div>
            <div class="text-right">
              <div class="right-num">
                <span v-if="detail.addUpWorkingHourPercentage >= 0"
                  ><img src="@/src/assets/member/up-icon.png" alt="" />+</span
                ><img v-else src="@/src/assets/member/down-icon.png" alt="" />{{
                  detail.addUpWorkingHourPercentage || 0
                }}%
              </div>
              <div class="right-tit opt5">
                {{ $t("member.floatingfromlastmonthvalue") }}
              </div>
            </div>
          </div> -->
          <div class="work-item">
            <div class="left-tit">
              {{ $t("member.Businesstrip") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('member.BusinesstripDesc')"
                placement="right"
                popper-class="draw_share_atooltip"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>
            <div class="fjsba">
              <div class="text-left">
                <div class="left-num">
                  <!-- <strong class="c74">{{ detail.travelDays || 0 }}</strong>
                <span>{{ $t("member.days") }}</span>
                &nbsp; -->
                  <strong class="c74">{{
                    detail.travelTime === null ? "-" : detail.travelTime
                  }}</strong>
                  <span>{{ $t("member.times") }}</span>
                </div>
              </div>
              <div class="text-right f14">
                <div class="right-tit fjsba tal">
                  <p>{{ $t("member.Abroad") }}</p>
                  <p>
                    <span class="c61 tac">{{
                      detail.foreignTravelTime === null
                        ? "-"
                        : detail.foreignTravelTime
                    }}</span>
                    {{ $t("member.Times") }}
                  </p>
                </div>
                <div class="right-tit fjsba tal">
                  <p>{{ $t("member.Domestic") }}</p>
                  <p>
                    <span class="c61 tac">{{
                      detail.domesticTravelTime === null
                        ? "-"
                        : detail.domesticTravelTime
                    }}</span>
                    {{ $t("member.Times") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="states-list fjsba">
          
        </div> -->
        <div class="states-list leave fjsba">
          <div class="work-item">
            <div class="left-tit">
              {{ $t("member.Cumlativeleavedays") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('member.CumlativeleaveDesc')"
                placement="right"
                popper-class="draw_share_atooltip"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>
            <div class="fjsba">
              <div class="text-left">
                <div class="left-num cdd">
                  {{ detail.addUpLeaveDay === null ? "-" : detail.addUpLeaveDay
                  }}<span>{{ $t("member.days") }}</span>
                </div>
              </div>
              <div class="text-right">
                <div class="leave-tit">
                  {{ $t("member.Annualleave") }}
                  <span class="c91">{{
                    detail.addUpLeaveDayAnnel === null
                      ? "-"
                      : detail.addUpLeaveDayAnnel
                  }}</span>
                  {{ $t("member.Sickleave") }}
                  <span class="cd4">{{
                    detail.addUpLeaveDaySick === null
                      ? "-"
                      : detail.addUpLeaveDaySick
                  }}</span>
                  {{ $t("member.leaveofabsence")
                  }}<span class="cdd">{{
                    detail.addUpLeaveDayMatter === null
                      ? "-"
                      : detail.addUpLeaveDayMatter
                  }}</span>
                  {{ $t("member.Others")
                  }}<span class="c6d">{{
                    detail.addUpLeaveDayOther === null
                      ? "-"
                      : detail.addUpLeaveDayOther
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">
        {{ $t("member.Myperformance") }}
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('member.MyperformanceDesc')"
          placement="right"
          popper-class="draw_share_atooltip"
        >
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </p>
      <div class="exp-block">
        <div class="exp-item">
          <img src="@/src/assets/member/states-01.png" alt="" />
          <div class="exp-box">
            <p class="exp-tit">
              {{ $t("member.recommendationtimes") }}
              <span class="c53">{{
                detail.employeeInterpolateTime === null
                  ? "-"
                  : detail.employeeInterpolateTime
              }}</span>
            </p>
            <p class="exp tit">
              {{ $t("member.successfulcandidate") }}
              <span class="c53">{{
                detail.successfulEmployeeInterpolateTime === null
                  ? "-"
                  : detail.successfulEmployeeInterpolateTime
              }}</span>
            </p>
          </div>
        </div>
        <div class="exp-item">
          <img src="@/src/assets/member/states-02.png" alt="" />
          <div class="exp-box">
            <p class="exp-tit">
              {{ $t("member.EDCregistration") }}
              <span class="cd4">{{
                detail.edcRegisterNums === null ? "-" : detail.edcRegisterNums
              }}</span>
            </p>
            <p class="exp tit">
              {{ $t("member.EDCparticipation") }}
              <span class="cd4">{{
                detail.edcParticipateNums === null
                  ? "-"
                  : detail.edcParticipateNums
              }}</span>
            </p>
          </div>
        </div>
        <div class="exp-item">
          <img src="@/src/assets/member/states-03.png" alt="" />
          <div class="exp-box">
            <p class="exp-tit">
              {{ $t("member.publicwelfare") }}
              <span class="cdd">{{
                detail.publicBenefitActivitiesTime === null
                  ? "-"
                  : detail.publicBenefitActivitiesTime
              }}</span>
            </p>
          </div>
        </div>
        <!-- <div class="exp-item">
          <img src="@/src/assets/member/states-01.png" alt="" />
          <div class="exp-box">
            <p class="exp-tit">产品推荐次数 <span>-</span></p>
            <p class="exp tit">产品推荐成功次数 <span>-</span></p>
            <p class="exp tit">产品推荐成功率 <span>-</span></p>
          </div>
        </div>
        <div class="exp-item">
          <img src="@/src/assets/member/states-02.png" alt="" />
          <div class="exp-box">
            <p class="exp-tit">知识库贡献次数 <span>-</span></p>
            <p class="exp tit">排名 <span>-</span></p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="block-item">
      <p class="block-title">{{ $t("member.RecordRrewards") }}</p>
      <div class="reward-block">
        <li class="reward-list header">
          <span class="date">{{ $t("member.Date") }}</span>
          <span class="type">{{ $t("member.Type") }}</span>
          <span class="desc">{{ $t("member.Awardreason") }}</span>
          <img
            src="@/src/assets/member/reward-icon.png"
            alt=""
            class="right-pic"
          />
        </li>
        <ul class="reward-table" style="overflow: auto">
          <li class="reward-list" v-for="(item, i) in rewardList" :key="i">
            <span class="date">{{ item.rewardsPunishmentDate }}</span>
            <span class="type">{{ item.rewardsPunishmentType }}</span>
            <span class="desc">{{ item.rewardsPunishmentReason }}</span>
          </li>
          <div class="list-empty" v-if="!rewardList.length">
            <img src="@/src/assets/member/empty.png" alt="" />
            <p>{{ $t("member.Norecordscurrently") }}</p>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "workStates",
  props: ["id"],
  data() {
    return {
      detail: {
        fieldOfficeRatePercentage: null,
        averageWorkingHourPercentage: null,
      },
      rewardList: [],
      count: 10,
      pageTotal: 0,
      query: {
        current: 1,
        size: 100,
      },
    };
  },
  mounted() {
    //this.id = this.utils.decryption(this.$route.query.id);
    this.getList();
    this.getReward();
  },
  methods: {
    ...mapActions({
      rewardInfo: "emp/rewardInfo",
      jobStatus: "emp/jobStatus",
    }),
    ToBreak(val) {
      return val.replaceAll("。", "<br />");
    },
    async getList() {
      let res = await this.jobStatus(this.id);
      console.log(res);
      this.detail = res.data;
    },
    async getReward() {
      let params = { id: this.id, query: this.query };
      let res = await this.rewardInfo(params);
      this.rewardList = res.data.records;
      this.query.current = res.data.current;
      this.pageTotal = res.data.total;
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.work-states {
  //padding-top: 0;
  .block-item {
    position: relative;
    margin-top: 0;
    margin-bottom: toPad(20);
    border-bottom: 1px solid #fff;
    .right-pic {
      position: absolute;
      top: toPad(-34);
      right: toPad(20);
      width: toPad(48);
      height: toPad(75);
    }
    .states-block {
      margin-bottom: toPad(15);
      padding: toPad(20) 0 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      .states-list {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: toPad(25);
        padding-top: toPad(20);
        &.leave {
          .work-item {
            width: 100%;
            border-right: none !important;
          }
        }
        .work-item {
          width: 49.8%;
          padding: 0 toPad(20);
          &:first-of-type {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
          .left-tit {
            display: flex;
            align-items: center;
            margin-bottom: toPad(8);
            font-size: toPad(16);
            .tips {
              display: inline-block;
              width: toPad(15);
              height: toPad(15);
              margin-left: toPad(8);
              cursor: pointer;
            }
          }
          .text-left {
            display: flex;
            align-items: flex-end;
            .left-num {
              position: relative;
              top: toPad(10);
              font-weight: 600;
              font-size: toPad(44);
              span {
                display: inline-block;
                margin-left: toPad(4);
                font-size: toPad(16);
                color: #000;
              }
            }
          }
          .text-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: toPad(20);
            text-align: right;
            .right-tit {
              text-align: right;
              font-size: toPad(12);
              span {
                display: inline-block;
                min-width: toPad(20);
                margin-left: toPad(3);
                color: #4a6756;
              }
              &.opt5 {
                opacity: 0.5;
              }
            }
            .right-num {
              font-weight: 600;
              font-size: toPad(20);
              img {
                width: toPad(20);
                height: toPad(20);
                margin-right: toPad(4);
              }
            }
            .leave-tit {
              position: relative;
              top: toPad(6);
              font-size: toPad(16);
              span {
                display: inline-block;
                margin: 0 toPad(16) 0 toPad(4);
                font-weight: 600;
                font-size: toPad(24);
                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .block-title {
      display: flex;
      align-items: center;
      .tips {
        display: inline-block;
        width: toPad(15);
        height: toPad(15);
        margin-left: toPad(8);
        cursor: pointer;
      }
    }
    .exp-block {
      display: flex;
      flex-wrap: wrap;
      padding-top: toPad(16);
      .exp-item {
        display: flex;
        align-items: center;
        width: 49.8%;
        // height: toPad(50);
        margin-bottom: toPad(20);

        &:nth-of-type(1) {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
        &:nth-of-type(2) {
          padding-left: toPad(40);
        }
        img {
          width: toPad(50);
          height: toPad(50);
          margin-right: toPad(12);
        }
        .exp-box {
          p {
            font-size: toPad(16);
            line-height: toPad(24);
            span {
              display: inline-block;
              margin-left: toPad(15);
            }
          }
        }
      }
    }
    .reward-block {
      width: 100%;
      min-height: toPad(420);
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-size: toPad(14);
      overflow-y: auto;
      .reward-list {
        display: flex;
        align-items: center;
        min-height: toPad(42);
        line-height: 1.1;
        padding-left: toPad(20);
        &.header {
          background: #8ccbc6;
          font-size: toPad(16);
          color: #fff;
        }
        span {
          //display: inline-block;
          white-space: wrap;
          &.date {
            width: 30%;
          }
          &.type {
            width: 30%;
          }
          &.desc {
            width: 40%;
          }
        }
      }
      .reward-table {
        height: toPad(378);
        .reward-list {
          display: flex;
          align-items: center;
          min-height: toPad(52);
          &:nth-of-type(even) {
            background: #edf7f3;
          }
        }
        .list-empty {
          margin: toPad(112) 0;
          text-align: center;
          img {
            width: toPad(90);
            height: toPad(90);
          }
          p {
            text-align: center;
            font-size: toPad(16);
          }
        }
      }
    }
  }
}
</style>
